.footer_main {
  padding: 60px 0;
  border-top: 1px solid gray;
}

.footer_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 24px;
  color: #000000;
}

.footer_hr_block_line {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 99.62%;
  background: #DFE0E6;
}

.footer_p {
  color: #676B7E;
}

.footer_p_second {
  color: #676B7E;
}

.footer_p1 {
  color: #676B7E;
}

.footer_p2 {
  color: #676B7E;
}

.footer_scial_media_icons {

  color: #676B7E;
  font-size: 24px;
}



.footer_main_btn {
  height: 41px;
  width: 141px;
  padding: 4px;
}


.footer_google_play {
  width: 100%;
}

.footer_app_store {
  width: 100%;
  height: 43px;
}

.social_media_icons_main {
  margin: 0 18px 0 0;
}

.footer_main {
  padding: 50px 0 0 0;
}

.footer_col {
  margin: 20px 0px;
  height: 100px;
}

.footer_last {
  padding-top: 10px;
}

.img_f {
  position: relative;
  width: 100%;
  margin-top: 86px;
}

.footer_down {
  background: gainsboro;
  text-align: center;
  margin-top: 18px;
}

.p_footer {
  color: #83889E;
  margin: 0;
}

.footer_span {
  color: #6200EA;
  border-bottom: 1px solid #6200EA;
}

@media screen and (max-width: 820px) {
  .footer_main {
    display: flex !important;
    flex-direction: column !important;
    padding: 50px 0;
    text-align: center;
  }

  .footer_col {
    margin: 20px 0px;
  }

  .footer_main_btn {
    height: 100%;
  }

  .footer_app_store {
    width: 69%;
    height: 70%;
  }

  .footer_google_play {
    width: 66%;
  }
}

@media screen and (max-width: 420px) {
  .footer_main {
    display: flex !important;
    flex-direction: column !important;
    padding: 50px 0;
    text-align: center;
  }

  .footer_col {
    margin: 20px 0px;
  }

  .footer_main_btn {
    height: 100%;
  }

  .footer_app_store {
    height: 70%;
  }
}