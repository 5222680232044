#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.nav_logo_color {
  color: #fff;
  /* position: relative; */
  width: 26px;

}

.nav_logo_head {
  position: absolute;
  top: 27%;
  left: 9%;
  font-size: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;

}

.header_nav_logo {
  position: relative;
  left: 2%;
  top: 25%;
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 10px;
}


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #f6f8fa;
  display: flex;
  align-items: center;

}

.site-layout-background {
  background: #fff;
}


#components-layout-demo-responsive .logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.indv-layout .ant-layout-header {

  box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 7%);
  z-index: 1;
  background-color: lightgray !important;
}

.ant-layout-header{
  padding:  45px !important;
}

.site-layout-sub-header-background {
  background: #f6f8fa !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.site-layout-sub-header-background2 {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
}

.site-layout-background {
  background: #fff !important;
}

.logo_img1 {
  width: 73px;
  height: 36px;
  margin: 3px
}
.row_col {
  background-color: #1890ff;
}

.gutter-row {
  border-radius: 10px;
  height: 40px;
  background-color: red;
}
.dashboard_img {
  width: 100%;
  height: 207px;
}

.main-content4 {
  margin-left: 10px;
  background-color: tomato;
  height: 211px;
  border-radius: 10px;
}


.site-layout-sub-header-background {
  justify-content: space-between !important;
}

.home-logo-head {
  height: 64px !important;
  /* width: 16%; */
  display: flex;
  justify-content: center;
  align-items: center;

}


.heading_logo {
  margin-right: 12px;
  font-size: 14px;
  color: whitesmoke !important;
}

.heading_logo a{
  position: relative;
}

.head_right_portion {
  color: black;
  border: none;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 31%;
}

.header_right_portion_btn {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}


.input_search_nav {
  display: flex;
  align-items: center;
  width: 54%;
  justify-content: space-between;
}

.input_nav {
  position: relative;
  border-radius: 6px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 4px 50px 4px 32px;
}

.search_icon_nav {
  position: absolute;
  font-size: 20px;
  margin: 0 0 0 5px;
}

.logo_text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@media screen and (max-width: 820px) {
  .ant-layout-header {
    flex-wrap: wrap;
    height: 120px !important;
    padding: 0 !important;
  }

  .head_right_portion {
    width: 100%;
    padding: 0px 21px;
    margin: 12px 0px;
  }

  .home-logo-head {
    width: 100%;
    text-align: center;
  }

  .input_nav {
    width: 75%;
  }

  .nav_logo_head {
    position: relative;
    top: 23%;
    left: 8%;
    font-size: 10px;
  }

  .header_nav_logo {
    position: absolute;
    left: 38%;
    top: 12%;
  }

  .nav_logo_color {
    width: 30px;
  }
}

@media screen and (max-width: 420px) {
  .ant-layout-header {
    flex-wrap: wrap;
    height: 100px !important;
    padding: 0 !important;
  }

  .head_right_portion {
    width: 100%;
    margin: 0 0 12px 0px;
  }

  .home-logo-head {
    width: 100%;
    text-align: center;
  }

  /* .nav_logo_head {
    position: absolute;
    top: 23%;
    left: 0%;
  }

  .header_nav_logo {
    position: absolute;
    left: 27%;
    top: 16%;
  }

  .nav_logo_color {
    width: 29px;
  } */
}