.main_login {
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin: 120px 0 110px 0;
    display: flex;
    flex-direction: column;
}

.form_main {
    border: 1px solid white;
    padding: 18px;
    background-color:  #FFFFFF;
    height: 325px;
}

.email_input {
    width: 100%;
}

.ant-form-item-control-input {
    width: 467px;
}

.ant-col-offset-8 {
    margin: 0;
}

.ant-btn-primary {
    width: 100%;
    background: #364F6B;
    margin-top: 16px;
    padding: 5px 34px 32px;

}
.login_head{
    color: #FFFFFF;
}

.anticon svg {
    color: #3C3E49;
}

.email_main {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;


}

.login_btn_p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #83889E;
    margin-top: 12px;

}

.anticon[tabindex] {
    cursor: pointer;
    padding: 5px;
}