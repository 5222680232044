/* =============Home page ================*/

.Main_Container {
  /* background: #E5E5E5; */
}
.Container{
  padding: 0 45px;
}
.banner_first_block {
  height: 495px;
  position: relative;
}

.banner_first_block_head_text {
  position: absolute;
  position: absolute;
  top: 48%;
  left: 9%;
}

.banner_first_block_banner_head {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;

}

.banner_first_block_banner_p {
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 34px;
color: #FFFFFF;
}

.second_row {
  justify-content: center;
}

.second_row_link {
  display: flex;
  justify-content: space-between;
  width: 72%;
}

.link_first {
  font-size: 23px;
  list-style: none;
}

.ProfileBgImg {
  width: 100%;
  height: 100%;
}

.second_block_p{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}

.site-layout-sub-header-background {
  justify-content: space-between !important;
}

.content_title_part {
  margin: 0 50px;
}

.content_title_part_row {
  margin: 10px;
}



.first_block_text {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 77px;
  flex-direction: column;
  padding: 60px 0;
}

.first_block_text_main {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 59px;
}

.first_block_text_main_link {
  color: #3FC1CB;
}

.second_block_left_part {
  background-color: lightgray;
  width: 100%;

}

.second_block_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.02em;
}

.second_block_right_part {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 50px;
}

/*============ Third block =========*/

.third_values_block {
  padding: 60px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F5F5F5;
  height: auto;
}

.third_values_block_heading {
  color: #364F6B;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
}

.third_values_block_main_part1 {

  align-items: center;
  justify-content: center;
  padding: 13px 275px;
  margin-top: 28px;
}

.third_values_block_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.third_values_block_main_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3FC1CB;
}

.third_values_block_main_inner_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.third_values_block_main_p_h {
  padding: 0 24px;
  font-style: normal;
  font-family: 'Rubik';

}

.values_hr_line {
  width: 100%;
  justify-content: center;
  display: flex;
}

.values_hr_line_main {
  width: 55%;
  height: 2px;
  background: #3FC1CB;
  border: none;
  margin-top: 11px;
}

.third_values_block_main_inner_p{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
text-align: center;
color: #000000;
}



/*============ Forth block =========*/
.Fourth_Testimonial_block {
  background: none;
}

.Fourth_testimonials_block_main_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #3C3E49;

}

.forth_testimonial_block_main_part1 {
  padding: 13px 158px;
  margin-top: 28px;
}

.fourth_testimonial_block_p_h {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #3C3E49;

}


.ant-statistic {
  text-align: center;
}

.ant-layout-content {
  flex: none !important;

}

@media screen and (max-width: 820px) {
  .head_right_portion {
    width: 98%;
  }

  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }

  .idv-login-g-btn button {
    width: 150px;
  }

  .idv-login-f-btn button {
    width: 150px;
  }

  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
}

@media screen and (max-width: 420px) {

  .third_values_block_main_part1 {
    padding: 0;
  }

  .third_values_block {
    height: auto;
  }

  .content_title_part {
    margin: 0;
  }

  .first_block_text_main {
    font-size: 12px;
    line-height: 26px;
  }
}