@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.nav_logo_color {
  color: #fff;
  /* position: relative; */
  width: 26px;

}

.nav_logo_head {
  position: absolute;
  top: 27%;
  left: 9%;
  font-size: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;

}

.header_nav_logo {
  position: relative;
  left: 2%;
  top: 25%;
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 10px;
}


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #f6f8fa;
  display: flex;
  align-items: center;

}

.site-layout-background {
  background: #fff;
}


#components-layout-demo-responsive .logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.indv-layout .ant-layout-header {

  box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 7%);
  z-index: 1;
  background-color: lightgray !important;
}

.ant-layout-header{
  padding:  45px !important;
}

.site-layout-sub-header-background {
  background: #f6f8fa !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.site-layout-sub-header-background2 {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
}

.site-layout-background {
  background: #fff !important;
}

.logo_img1 {
  width: 73px;
  height: 36px;
  margin: 3px
}
.row_col {
  background-color: #1890ff;
}

.gutter-row {
  border-radius: 10px;
  height: 40px;
  background-color: red;
}
.dashboard_img {
  width: 100%;
  height: 207px;
}

.main-content4 {
  margin-left: 10px;
  background-color: tomato;
  height: 211px;
  border-radius: 10px;
}


.site-layout-sub-header-background {
  justify-content: space-between !important;
}

.home-logo-head {
  height: 64px !important;
  /* width: 16%; */
  display: flex;
  justify-content: center;
  align-items: center;

}


.heading_logo {
  margin-right: 12px;
  font-size: 14px;
  color: whitesmoke !important;
}

.heading_logo a{
  position: relative;
}

.head_right_portion {
  color: black;
  border: none;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 31%;
}

.header_right_portion_btn {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}


.input_search_nav {
  display: flex;
  align-items: center;
  width: 54%;
  justify-content: space-between;
}

.input_nav {
  position: relative;
  border-radius: 6px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 4px 50px 4px 32px;
}

.search_icon_nav {
  position: absolute;
  font-size: 20px;
  margin: 0 0 0 5px;
}

.logo_text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@media screen and (max-width: 820px) {
  .ant-layout-header {
    flex-wrap: wrap;
    height: 120px !important;
    padding: 0 !important;
  }

  .head_right_portion {
    width: 100%;
    padding: 0px 21px;
    margin: 12px 0px;
  }

  .home-logo-head {
    width: 100%;
    text-align: center;
  }

  .input_nav {
    width: 75%;
  }

  .nav_logo_head {
    position: relative;
    top: 23%;
    left: 8%;
    font-size: 10px;
  }

  .header_nav_logo {
    position: absolute;
    left: 38%;
    top: 12%;
  }

  .nav_logo_color {
    width: 30px;
  }
}

@media screen and (max-width: 420px) {
  .ant-layout-header {
    flex-wrap: wrap;
    height: 100px !important;
    padding: 0 !important;
  }

  .head_right_portion {
    width: 100%;
    margin: 0 0 12px 0px;
  }

  .home-logo-head {
    width: 100%;
    text-align: center;
  }

  /* .nav_logo_head {
    position: absolute;
    top: 23%;
    left: 0%;
  }

  .header_nav_logo {
    position: absolute;
    left: 27%;
    top: 16%;
  }

  .nav_logo_color {
    width: 29px;
  } */
}
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Bold.8ee87a5a.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rubik-Bold';
    src: url(/static/media/Rubik-Bold.11598c28.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* regular */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Regular.550c7b05.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Light.3745e321.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Medium.f2f36c73.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

/* thin */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Thin.da1833ee.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}




.footer_main {
  padding: 60px 0;
  border-top: 1px solid gray;
}

.footer_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 24px;
  color: #000000;
}

.footer_hr_block_line {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 99.62%;
  background: #DFE0E6;
}

.footer_p {
  color: #676B7E;
}

.footer_p_second {
  color: #676B7E;
}

.footer_p1 {
  color: #676B7E;
}

.footer_p2 {
  color: #676B7E;
}

.footer_scial_media_icons {

  color: #676B7E;
  font-size: 24px;
}



.footer_main_btn {
  height: 41px;
  width: 141px;
  padding: 4px;
}


.footer_google_play {
  width: 100%;
}

.footer_app_store {
  width: 100%;
  height: 43px;
}

.social_media_icons_main {
  margin: 0 18px 0 0;
}

.footer_main {
  padding: 50px 0 0 0;
}

.footer_col {
  margin: 20px 0px;
  height: 100px;
}

.footer_last {
  padding-top: 10px;
}

.img_f {
  position: relative;
  width: 100%;
  margin-top: 86px;
}

.footer_down {
  background: gainsboro;
  text-align: center;
  margin-top: 18px;
}

.p_footer {
  color: #83889E;
  margin: 0;
}

.footer_span {
  color: #6200EA;
  border-bottom: 1px solid #6200EA;
}

@media screen and (max-width: 820px) {
  .footer_main {
    display: flex !important;
    flex-direction: column !important;
    padding: 50px 0;
    text-align: center;
  }

  .footer_col {
    margin: 20px 0px;
  }

  .footer_main_btn {
    height: 100%;
  }

  .footer_app_store {
    width: 69%;
    height: 70%;
  }

  .footer_google_play {
    width: 66%;
  }
}

@media screen and (max-width: 420px) {
  .footer_main {
    display: flex !important;
    flex-direction: column !important;
    padding: 50px 0;
    text-align: center;
  }

  .footer_col {
    margin: 20px 0px;
  }

  .footer_main_btn {
    height: 100%;
  }

  .footer_app_store {
    height: 70%;
  }
}
/* =============Home page ================*/

.Main_Container {
  /* background: #E5E5E5; */
}
.Container{
  padding: 0 45px;
}
.banner_first_block {
  height: 495px;
  position: relative;
}

.banner_first_block_head_text {
  position: absolute;
  position: absolute;
  top: 48%;
  left: 9%;
}

.banner_first_block_banner_head {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;

}

.banner_first_block_banner_p {
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 34px;
color: #FFFFFF;
}

.second_row {
  justify-content: center;
}

.second_row_link {
  display: flex;
  justify-content: space-between;
  width: 72%;
}

.link_first {
  font-size: 23px;
  list-style: none;
}

.ProfileBgImg {
  width: 100%;
  height: 100%;
}

.second_block_p{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}

.site-layout-sub-header-background {
  justify-content: space-between !important;
}

.content_title_part {
  margin: 0 50px;
}

.content_title_part_row {
  margin: 10px;
}



.first_block_text {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 77px;
  flex-direction: column;
  padding: 60px 0;
}

.first_block_text_main {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 59px;
}

.first_block_text_main_link {
  color: #3FC1CB;
}

.second_block_left_part {
  background-color: lightgray;
  width: 100%;

}

.second_block_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.02em;
}

.second_block_right_part {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 50px;
}

/*============ Third block =========*/

.third_values_block {
  padding: 60px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F5F5F5;
  height: auto;
}

.third_values_block_heading {
  color: #364F6B;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
}

.third_values_block_main_part1 {

  align-items: center;
  justify-content: center;
  padding: 13px 275px;
  margin-top: 28px;
}

.third_values_block_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.third_values_block_main_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3FC1CB;
}

.third_values_block_main_inner_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.third_values_block_main_p_h {
  padding: 0 24px;
  font-style: normal;
  font-family: 'Rubik';

}

.values_hr_line {
  width: 100%;
  justify-content: center;
  display: flex;
}

.values_hr_line_main {
  width: 55%;
  height: 2px;
  background: #3FC1CB;
  border: none;
  margin-top: 11px;
}

.third_values_block_main_inner_p{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
text-align: center;
color: #000000;
}



/*============ Forth block =========*/
.Fourth_Testimonial_block {
  background: none;
}

.Fourth_testimonials_block_main_heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #3C3E49;

}

.forth_testimonial_block_main_part1 {
  padding: 13px 158px;
  margin-top: 28px;
}

.fourth_testimonial_block_p_h {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #3C3E49;

}


.ant-statistic {
  text-align: center;
}

.ant-layout-content {
  flex: none !important;

}

@media screen and (max-width: 820px) {
  .head_right_portion {
    width: 98%;
  }

  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }

  .idv-login-g-btn button {
    width: 150px;
  }

  .idv-login-f-btn button {
    width: 150px;
  }

  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
}

@media screen and (max-width: 420px) {

  .third_values_block_main_part1 {
    padding: 0;
  }

  .third_values_block {
    height: auto;
  }

  .content_title_part {
    margin: 0;
  }

  .first_block_text_main {
    font-size: 12px;
    line-height: 26px;
  }
}
.main_login {
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin: 120px 0 110px 0;
    display: flex;
    flex-direction: column;
}

.form_main {
    border: 1px solid white;
    padding: 18px;
    background-color:  #FFFFFF;
    height: 325px;
}

.email_input {
    width: 100%;
}

.ant-form-item-control-input {
    width: 467px;
}

.ant-col-offset-8 {
    margin: 0;
}

.ant-btn-primary {
    width: 100%;
    background: #364F6B;
    margin-top: 16px;
    padding: 5px 34px 32px;

}
.login_head{
    color: #FFFFFF;
}

.anticon svg {
    color: #3C3E49;
}

.email_main {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;


}

.login_btn_p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #83889E;
    margin-top: 12px;

}

.anticon[tabindex] {
    cursor: pointer;
    padding: 5px;
}
:root {
  --color-primary: #3f7a4f;
  /* --color-dark-grey: #8b8f95; */
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;

  /* BrandSetting Start */
  --button-primary: #3f7a4f;
  --button-text-primary: #ffffff;
  --button-primary-hover: #f67054;
  --button-text-primary-hover: #ffffff;
  --color-RegularActiveText: #3f7a4f;
  /* BrandSetting End */
}

* {
  font-family: 'Rubik' !important;
}

body {
  background-color: #f6f8fa;
  padding-right: 0 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.modal-content {
  border-radius: 10px;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #3fc1cb;

}

.carousel .control-dots .dot {
  border: 2px solid #3fc1cb;
  height: 15px;
  width: 15px;
  background: #FFFFFF;
}

/* Skeleton CSS */
.skeleton-bg .react-loading-skeleton {
  background: linear-gradient(90deg, #F5F5F5 0%, rgba(245, 245, 245, 0.3) 100%);
  --highlight-color: none
}

.item-category-img .react-loading-skeleton {
  height: 144px;
  width: 144px;
}

.skeleton-adv-bg .react-loading-skeleton {
  height: 250px;
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  --highlight-color: none;
  border-radius: 5px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.skeleton-banner-bg .react-loading-skeleton {
  height: 337px;
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  border-radius: 5px;
  --highlight-color: none
}

.skeleton-category-bg {
  text-align: center;
}

.skeleton-category-bg .react-loading-skeleton {
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  border-radius: 20px;
  width: 50%;
  height: 50px;
  --highlight-color: none
}

.skelton-empty-basket .react-loading-skeleton {
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  --highlight-color: none;
  height: 130px;
  width: 130px;
}
iframe{
  pointer-events: none !important;
}
.Container{
  padding: 0 45px !important;
}
@media only screen and (min-width: 1024px) {
  .item-category-img .react-loading-skeleton {
    height: 100px;
    width: 100px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .item-category-img .react-loading-skeleton {
    height: 100px;
    width: 100px;
  }
}
